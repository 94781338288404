import React, { useMemo } from 'react';
import { Button, Fieldset, Panel, TextField } from 'react95';
import './play.scss';

import All from '../../Assets/All_Functional.gif';
import NetworkMotherboardPower from '../../Assets/network_motherboard_power.gif';
import MotherboardPower from '../../Assets/motherboard_power.gif';
import Power from '../../Assets/power.gif';
import AllDisabled from '../../Assets/all_disabled.gif';
import Defused from '../../Assets/defused.gif';

export default function Play ({ setError, initialState, ...props })
{
    const [messages, setMessages] = React.useState([]);
    const [inputText, setInputText] = React.useState("");
    const [step, setStep] = React.useState(1);
    const [inputEnabled, setInputEnabled] = React.useState(true);
    const [systemInformation, setSystemInformation] = React.useState("");
    const [rows] = React.useState(1);
    const [timerInterval, setTimerInterval] = React.useState(1);
    const [stopTimer, setStopTimer] = React.useState(false);
    const [timerFrozen, setTimerFrozen] = React.useState(false);
    const [image, setImage] = React.useState(All);
    const [components, setComponents] = React.useState(["Red", "Memoria", "Placa base", "Fuente de alimentación"])

    const timerDecrement = (state, action) => {
        if (action && (action.type === "setInitial")) {
            return action.payload;
        } else {
            return state - (1 + timePenalty);
        }
    }
    const [timePenalty, setTimePenalty] = React.useState(0);
    const [timer, timerDispatch] = React.useReducer(timerDecrement, 60 * 60)

    const userInput = React.createRef();

    // const setMessages = useCallback(obj => {
    //   setRealMessages(obj)
    //   // eslint-disable-next-line
    // }, [messages])

    React.useEffect(() => {
        localStorage.setItem('timer', JSON.stringify(timer));
        localStorage.setItem('messages', JSON.stringify(messages));
        localStorage.setItem('inputText', JSON.stringify(inputText));
        localStorage.setItem('step', JSON.stringify(step));
        localStorage.setItem('inputEnabled', JSON.stringify(inputEnabled));
        localStorage.setItem('systemInformation', JSON.stringify(systemInformation));
        localStorage.setItem('timerInterval', JSON.stringify(timerInterval));
        localStorage.setItem('stopTimer', JSON.stringify(stopTimer));
        localStorage.setItem('timerFrozen', JSON.stringify(timerFrozen));
        localStorage.setItem('image', JSON.stringify(image));
    }, [timer, messages, inputText, step, inputEnabled, systemInformation, timerInterval, stopTimer, timerFrozen, image]);

    React.useEffect(() => {
        console.log(initialState);
        if (Object.keys(initialState).length !== 0 && initialState.shouldLoad) {
            timerDispatch({ type: 'setInitial', payload: initialState.timer});
            setMessages(initialState.messages);
            setInputText(initialState.inputText);
            setStep(initialState.step);
            setSystemInformation(initialState.systemInformation);
            setTimerInterval(initialState.timerInterval);
            setStopTimer(initialState.stopTimer);
            setTimerFrozen(initialState.timerFrozen);
            setImage(initialState.image);
        } else {
            setMessages([
                {
                    you: false,
                    message: "Parece que hay cuatro módulos en el dispositivo de diseminación, y todos ellos alimentan la bomba principal. Si los desactivamos todos, eso inhabilitará el dispositivo."
                }
            ])
            setTimeout(() => {
                setTimeout(() => {
                    setMessages([
                        {
                            you: false,
                            message: "Parece que hay cuatro módulos en el dispositivo de diseminación, y todos ellos alimentan la bomba principal. Si los desactivamos todos, eso inhabilitará el dispositivo."
                        },
                        {
                            you: false,
                            message: `Tenemos que descubrir qué módulo debe ser el primero. Esta máquina parece muy delicada, así que tenemos que asegurarnos de desactivar los módulos en el sentido correcto. De lo contrario, eso podría tener consecuencias catastróficas. <br/><br/> Bueno, ¿entonces por dónde empezamos? <br/> ${remainingComponentString}`
                        }
                    ])
                }, 1500);
            }, 1500);
        }
    // eslint-disable-next-line
    }, [initialState]);

    const handleKeyDown = (ev) => {
        if (ev.keyCode === 13 && inputEnabled) {
            sendMessage();
        }
    }

    const wireCutFailMessage = useMemo(() => {
        if (!timerFrozen) {
            return 'Oh no, parece que ese no era el cable correcto… y el temporizador se ha modificado. Intenta no equivocarte en el próximo. <br/><br/>¿Qué cables quieres que corte?'
        } else {
            return 'Oh no, parece que ese no era el cable correcto… y el temporizador se ha modificado. Intenta no equivocarte en el próximo. <br/><br/>¿Qué cables quieres que corte?'
        }
    }, [timerFrozen])

    const ChatLog = React.useMemo(() => {
        return (
            <div style={{ position: 'absolute', left: 0, top: 0, paddingLeft: '1rem', paddingRight: '1rem' }}>
                { messages.map((m, i) => {
                    return (
                        <div key={`msg-${i}`} className={`message ${ m.you ? 'you' : 'them' }`}>
                            <div className="sender">{ m.you ? 'User:' : 'Claire:'}</div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: m.message }}/>
                        </div>
                    );
                })}
            </div>
        );
    }, [messages])

    const sendMessage = () => {
        handleInput(userInput.current.value);
        userInput.current.value = '';
    }

    const checkIfModule = (input) => {
        return ['red', 'fuente de alimentación', 'placa base', 'memoria'].includes(input)
    }

    const calculateTimerString = (currentTime, offset) => {
      if (offset) currentTime = currentTime - offset
      return `${Math.floor(currentTime/60) }:${ (currentTime % 60 < 10 ? `0${currentTime % 60}` : currentTime % 60)}`
    }

    const timerValue = useMemo(() => {
      return calculateTimerString(timer)
    }, [timer])

    const elapsedTime = (t, offset) => {
        return calculateTimerString((60 * 60) - t, offset)
    }

    const remainingComponentString = useMemo(() => {
        return components.map(a => `<br/>&bull; ${a}`).join('')
    }, [components])

    const handleInput = input => {
        let lowerInput = input.toLowerCase();
        let delay = 2000;

        setMessages([ ...messages, {
            you: true,
            message: input
        }]);

        // Computer Modules
        if (step === 1 || step === 5 || step === 7) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, probaré con ese componente…"
                    }
                ]);
            }, delay / 2)
        }

        // Memory hints
        if (step === 4 || step === 3 || step === 2) {
            setTimeout(() => {
                setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: "Ok, introduciré eso y a ver qué dice..."
                    }
                ]);
            }, delay / 2)
        }

        setTimeout(() => {
            if (step === 1) {
                //if (['red', 'placa base', 'fuente de alimentación', 'memoria'].includes(lowerInput)) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, probaré con ese componente… "
                        },
                        {
                            you: false,
                            message: `Me temo que esa opción no está disponible. Intentemoslo de nuevo.<br/><br/>  ¿Cuál será? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                    if (['red', 'placa base', 'fuente de alimentación'].includes(lowerInput)) {
                        setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, probaré con ese componente… "
                        },
                        {
                            you: false,
                            message: `Oh no, it seems like that was the wrong component, and worse still, it seems like the timers jumped down by another five minutes! Don't make another mistake, we can't afford to lose any more time! <br/><br/> ¿Cuál será? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                    }

                    if (lowerInput === 'memoria') {
                        setInputEnabled(false);
                        setComponents(components.filter(a => a !== 'Memoria'))
                        setSystemInformation("Hola, soy el sistema de memoria del dispositivo. Parece que parte de mi memoria se ha visto dañada. Es un placer conocerle, ¿le gustaría ser mi amigo?");

                        setTimeout(() => {
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, probaré con ese componente…"
                                },
                                {
                                    you: false,
                                    message: "Bien, puede que esa elección sea correcta. Parece que ha aparecido algo en el panel de información del sistema."
                                }
                            ]);

                            setTimeout(() => {
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, probaré con ese componente…"
                                    },
                                    {
                                        you: false,
                                        message: "Bien, puede que esa elección sea correcta. Parece que ha aparecido algo en el panel de información del sistema."
                                    },
                                    {
                                        you: false,
                                        message: "Parece que han borrado mucho del historial de la memoria del sistema, pero han dejado algún tipo de programa con inteligencia artificial que es un poco irritante."
                                    }
                                ]);

                                setTimeout(() => {
                                    setSystemInformation("Rara vez tengo la oportunidad de hablar con personas. Déjeme contarle una historia.");
                                    setTimeout(() => {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: "Ok, probaré con ese componente…"
                                            },
                                            {
                                                you: false,
                                                message: "Bien, puede que esa elección sea correcta. Parece que ha aparecido algo en el panel de información del sistema."
                                            },
                                            {
                                                you: false,
                                                message: "Parece que han borrado mucho del historial de la memoria del sistema, pero han dejado algún tipo de programa con inteligencia artificial que es un poco irritante."
                                            },
                                            {
                                                you: false,
                                                message: "Sospecho que hay algo en su historial que nos ayude a apagarlo. Mira a ver si puedes restaurar algunos de sus archivos borrados."
                                            }
                                        ]);

                                        setTimeout(() => {
                                            setSystemInformation("Oh no, parece que todas las historias de mi memoria se han borrado.");
                                            setTimeout(() => {
                                                setMessages([ ...messages,
                                                {
                                                    you: true,
                                                    message: input
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok, probaré con ese componente…"
                                                },
                                                {
                                                    you: false,
                                                    message: "Bien, puede que esa elección sea correcta. Parece que ha aparecido algo en el panel de información del sistema."
                                                },
                                                {
                                                    you: false,
                                                    message: "Parece que han borrado mucho del historial de la memoria del sistema, pero han dejado algún tipo de programa con inteligencia artificial que es un poco irritante."
                                                },
                                                {
                                                    you: false,
                                                    message: "Sospecho que hay algo en su historial que nos ayude a apagarlo. Mira a ver si puedes restaurar algunos de sus archivos borrados."
                                                },
                                                {
                                                    you: false,
                                                    message: "Vamos a intentar encontrar una palabra clave para restaurar una de las memorias del sistema. Estoy segura que todavía hay algo en su código. Quizás cuando sepamos más sobre el dispositivo podremos encontrar una manera para desactivarlo. Deberíamos empezar por la memoria reciente e ir hacia atrás en el tiempo. <br/><br/> - Mándame la palabra que crees que funcionaría."
                                                }
                                                ]);
                                                setInputEnabled(true);
                                                setStep(2);
                                            }, delay);
                                        }, 4000);
                                    }, delay);
                                }, delay);
                            }, delay)
                        }, delay);
                    }
                // }
                //else
                //{
                    //setMessages([ ...messages,
                        //{
                            //you: true,
                            //message: input
                       // },
                        //{
                           // you: false,
                           // message: "Me temo que esa opción no está disponible. Intentemoslo de nuevo. <br/><br/> ¿Cuál será? <br/><br/> &bull; Red <br/> &bull; Memoria <br/> &bull; Placa base <br/> &bull; Fuente de alimentación"
                       // }
               // }
            }

            if (step === 2) {
                if (lowerInput === 'salchichas') {
                    setSystemInformation('Oh Dios, lo estoy viendo todo. El envenenamiento de las salchichas en las charlas de paz. Tres personas muertas… y...yo fui el culpable, Dios mío. ¿Qué otras atrocidades habré cometido?');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, introduciré eso y a ver qué dice..."
                        },
                        {
                            you: false,
                            message: '¡Buen trabajo! Parece que quiere saber más sobre sí mismo. Vamos a intentar dar con otra palabra clave del pasado de esta máquina... <br/><br/> - Mándame la palabra que crees que funcionaría.'
                        }
                    ]);
                    setStep(3);
                } else {
                    setSystemInformation('¿Qué dices? Qué raros sois los humanos…');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, introduciré eso y a ver qué dice..."
                        },
                        {
                            you: false,
                            message: "Parece que esa no es la palabra a la que responde, vamos a probar con algo diferente... <br/><br/> - Mándame la palabra que crees que funcionaría."
                        }
                    ]);
                }
            }

            if (step === 3) {
                if (lowerInput === 'yemen') {
                    setSystemInformation('Toda esa gente… cubierta de petróleo crudo… y fuego. ¿Qué soy? ¿He estado siempre involucrado en incidentes tan horribles? Es demasiado… pero necesito recordar...');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, introduciré eso y a ver qué dice..."
                        },
                        {
                            you: false,
                            message: 'Quiere saber más. Puede que parezca raro complacer a una máquina con inteligencia artificial, pero hemos llegado tan lejos… Mira a ver si puedes encontrar otra palabra clave. <br/><br/> - Mándame la palabra que crees que funcionaría.'
                        }
                    ]);
                    setStep(4);
                } else {
                    setSystemInformation('¿Qué dices? Qué raros sois los humanos…');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, introduciré eso y a ver qué dice..."
                        },
                        {
                            you: false,
                            message: "Parece que esa no es la palabra a la que responde, vamos a probar con algo diferente... <br/><br/> - Mándame la palabra que crees que funcionaría."
                        }
                    ]);
                }
            }

            if (step === 4) {
                if (lowerInput === 'ornitorrinco') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                    ]);
                    setInputEnabled(false);
                    setSystemInformation('Ornitorrinco... Ornito...Creía que no había peor memoria que ver morir a esas personas en Yemen, pero la destrucción sin sentido de todos esos relojes de cuco ya es demasiado. No puedo con esto… Alguien tiene que detenerme. Tengo que detenerme a mí mismo.');
                    setTimeout(() => {
                        setSystemInformation('--- LA MEMORIA SE AUTO DESTRUIRÁ AHORA ---');
                        setTimeout(() => {
                            setSystemInformation('--- Sistema de memoria desactivado ---');
                            setImage(NetworkMotherboardPower);
                            setTimeout(() => {
                                setSystemInformation("");
                                setMessages([ ...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: "Ok, introduciré eso y a ver qué dice..."
                                    },
                                    {
                                        you: false,
                                        message: `Bueno, eso ha sido raro. Pero parece que hemos conseguido desactivar uno de los componentes. Solo nos quedan tres. <br/><br/> ¿Cuál es el siguiente componente? <br/> ${remainingComponentString}`
                                    },
                                ]);
                                setInputEnabled(true);
                                setStep(5);
                            }, 4000);
                        }, 4000)
                    }, 8000)
                } else {
                    setSystemInformation('¿Qué dices? Qué raros sois los humanos…');
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, introduciré eso y a ver qué dice..."
                        },
                        {
                            you: false,
                            message: "Parece que esa no es la palabra a la que responde, vamos a probar con algo diferente... <br/><br/> - Mándame la palabra que crees que funcionaría."
                        }
                    ]);
                }
            }

            if (step === 5) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, probaré con ese componente…"
                        },
                        {
                            you: false,
                            message: `Me temo que esa opción no está disponible. Intentemoslo de nuevo.<br/><br/> ¿Cuál será? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (['placa base', 'fuente de alimentación'].includes(lowerInput)) {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, probaré con ese componente…"
                        },
                        {
                            you: false,
                            message: `¡Oh, no! Ese es el componente equivocado. El temporizador acaba de bajar cinco minutos.<br/><br/> ¿Cuál es el siguiente componente? <br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'red') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation("La red de la Planta de tratamiento de aguas Z8\n\n Todo nodo de esta red está conectado a otros dos nodos, creando un circuito que contiene todos los nodos de la red. Cada nodo solo puede estar conectado a otro que comparta al menos una parte de su nombre (en cualquier posición), pero no puede estar conectado a dos nodos que comparten la misma parte de su numbre, es decir, el nodo A1B podría conectarse bien al nodo A2C o D3A, pero no a ambos.");

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, probaré con ese componente…"
                            },
                            {
                                you: false,
                                message: 'Bien, parece que eso es correcto. Parece sensato desactivar el dispositivo del WiFi para que Yakov no se pueda comunicar con él en remoto.<br/><br/>Acaba de aparecer algo en la pantalla de información del sistema'
                            }
                        ]);

                        setTimeout(() => {
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: "Ok, probaré con ese componente…"
                                },
                                {
                                    you: false,
                                    message: 'Bien, parece que eso es correcto. Parece sensato desactivar el dispositivo del WiFi para que Yakov no se pueda comunicar con él en remoto.<br/><br/>Acaba de aparecer algo en la pantalla de información del sistema'
                                },
                                {
                                    you: false,
                                    message: 'Tenemos que encontrar los dos nodos conectados a la red de nodos de este dispositivo. Una vez sepa qué dos nodos son, podré desactivarlos.<br/><br/>Cuando sepas los dos nodos, introdúcelos a la vez, separados por una coma.'
                                }
                            ]);
                            setComponents(components.filter(a => a !== 'Red'))
                            setInputEnabled(true);
                            setStep(6);
                        })
                    }, 3000)
                }
            }

            if (step === 6) {
                const correct = ["K5C", "B4E"];
                const validButIncorrect = ["C3B", "B4E", "D4A", "1F4", "A2G", "G6J", "B7D", "8F2", "6J2", "K5C"];
                const valid = correct.concat(validButIncorrect)
                let hasInvalid = false

                console.log('Valid', valid)

                let values = lowerInput.split(',');

                const onlyUnique = (value, index, self) => {
                    return self.indexOf(value) === index;
                }

                values = values.map((i, index) => {
                    return i.trim();
                });

                values = values.filter(onlyUnique); // You can't cheat by using one correct answer 3 times.

                values.forEach(val => {
                    if (!valid.includes(val.toUpperCase())) hasInvalid = true
                })

                if (hasInvalid) {
                    return setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Cuando sepas los dos nodos, introdúcelos a la vez, separados por una coma."
                        }
                    ])
                }

                if (values.length === 2 // Prevents errors from undefined indexes.
                    &&
                    correct.includes(values[0].toUpperCase())
                    &&
                    correct.includes(values[1].toUpperCase())
                    // &&
                    // correct.includes(values[2].toUpperCase())
                ) {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);
                    setTimeout(() => {
                        setSystemInformation('--- Sistema de red desactivado ---');
                        setImage(MotherboardPower);
                        setTimeout(() => {
                            setSystemInformation('');
                            setMessages([...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: 'Bueno, parece que eso ha funcionado de fábula. Ahora sabemos que Yakov no podrá comunicarse con el dispositivo en remoto.'
                                }
                            ]);
                            setTimeout(() => {
                                setMessages([...messages,
                                    {
                                        you: true,
                                        message: input
                                    },
                                    {
                                        you: false,
                                        message: 'Bueno, parece que eso ha funcionado de fábula. Ahora sabemos que Yakov no podrá comunicarse con el dispositivo en remoto.'
                                    },
                                    {
                                        you: false,
                                        message: `Vamos con la siguiente sección. ¿Cuál debería ser? <br/> ${remainingComponentString}`
                                    }
                                ]);
                                setStep(7);
                                setInputEnabled(true);
                            }, delay);
                        }, delay);
                    }, delay);

                } else {
                    setInputEnabled(false);
                    setMessages([...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setTimeout(() => {
                        setMessages([...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: 'Hmmm, he intentado apagar esos dos, pero parece que el dispositivo está conectado a la red. Volveré a conectar ambos nodos por ahora para no levantar sospechas. <br/><br/> Échale otro vistazo y dime qué dos nodos quieres que desconecte.'
                            }
                        ]);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 7) {

                if (!checkIfModule(lowerInput)) {
                    return setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, probaré con ese componente…"
                        },
                        {
                            you: false,
                            message: `Me temo que esa opción no está disponible. Intentemoslo de nuevo.<br/><br/> ¿Cuál será? <br/> ${remainingComponentString}`
                        }
                    ]);
                }

                if (lowerInput === 'fuente de alimentación') {
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, probaré con ese componente…"
                        },
                        {
                            you: false,
                            message: `¡Oh no! Ese es el componente equivocado. El temporizador acaba de bajar cinco minutos.<br/><br/>¿Cuál será?<br/> ${remainingComponentString}`
                        }
                    ]);

                    setTimePenalty(5 * 60);
                }

                if (lowerInput === 'placa base') {
                    setInputEnabled(false);

                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        }
                    ]);

                    setSystemInformation('Estoy lista para recibir instrucciones. Puedo hacer dos a la vez.  \n \n Temperatura interna: 27 grados');

                    setTimeout(() => {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input
                            },
                            {
                                you: false,
                                message: "Ok, probaré con ese componente…"
                            },
                            {
                                you: false,
                                message: 'Parece que la placa base de este extraño dispositivo puede realizar dos comandos a la vez. <br/><br/> Quizás haya una manera de aprovechar eso y dar con dos comandos que apaguen la placa base... <br/><br/> Escribe ambos separados por una coma y yo los introduciré.'
                            }
                        ]);
                        setComponents(components.filter(a => a !== 'Placa base'))
                        setStep(8);
                        setInputEnabled(true);
                    }, delay);
                }
            }

            if (step === 8) {
                let commands = lowerInput.split(',');
                commands = commands.map((v, i) => {
                    return v.trim();
                });

                let validCommands = {
                    'cocinar un crepe': 42,
                    'abrir puerta del garaje': 9,
                    'encender luces': 13,
                    'pasar la aspiradora': 37,
                    'secar manos': 17,
                    'tostar pan': 28
                }

                if (commands.length === 2
                    &&
                    validCommands[commands[0]]
                    &&
                    validCommands[commands[1]]
                    //&&
                    //validCommands[commands[2]]
                ) {
                    setInputEnabled(false);
                    setMessages([
                        ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: "Ok, voy a probar esos dos, dame un segundo."
                        }
                    ]);

                    //let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]] + validCommands[commands[2]];

                    let temp = 27 + validCommands[commands[0]] + validCommands[commands[1]];

                    if (temp !== 77) {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nTemperatura interna: ${temp} grados`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nTemperatura interna: ${temp} grados\n\nPlaca base estable.`);
                                setMessages([
                                    ...messages,
                                    {
                                        you: true,
                                        message: input.split('\n').join('<br/>')
                                    },
                                    {
                                        you: false,
                                        message: "Ok, voy a probar esos tres, dame un segundo."
                                    },
                                    {
                                        you: false,
                                        message: `Esos dos comandos han hecho que la temperatura de la placa base aumente ${temp} grados. Parece que ahora ha bajado a 27 grados.<br/><br/>Vamos a probar con otros dos comandos.`
                                    }
                                ]);
                                setTimeout(() => {
                                    setSystemInformation('Estoy lista para recibir instrucciones. Puedo hacer dos a la vez. \n \n Temperatura interna: 27 grados');
                                    setInputEnabled(true);
                                }, delay);
                            }, delay);
                        }, delay);
                    } else {
                        setTimeout(() => {
                            setSystemInformation(`${input}\n\nTemperatura interna: ${temp} grados`);
                            setTimeout(() => {
                                setSystemInformation(`${input}\n\nTemperatura interna: ${temp} grados\n\n--- TEMPERATURA CRÍTICA ---`);
                                setTimeout(() => {
                                    setSystemInformation("--- Placa base apagada ---");
                                    setImage(Power);
                                    setTimeout(() => {
                                        setSystemInformation("");
                                        setTimeout(() => {
                                            setMessages([
                                                ...messages,
                                                {
                                                    you: true,
                                                    message: input.split('\n').join('<br/>')
                                                },
                                                {
                                                    you: false,
                                                    message: "Ok, voy a probar esos dos, dame un segundo."
                                                },
                                                {
                                                    you: false,
                                                    message: "Buen trabajo, parece que hemos conseguido destruir la placa base.<br/><br/>Vamos a por el último componente, la fuente de alimentación."
                                                }
                                            ]);
                                            setTimeout(() => {
                                                setSystemInformation("El primer nivel de cables: \n Rojo, verde, azul, negro, rosa.");
                                                setTimeout(() => {
                                                    setMessages([
                                                        ...messages,
                                                        {
                                                            you: true,
                                                            message: input.split('\n').join('<br/>')
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Ok, voy a probar esos dos, dame un segundo."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Buen trabajo, parece que hemos conseguido destruir la placa base.<br/><br/>Vamos a por el último componente, la fuente de alimentación."
                                                        },
                                                        {
                                                            you: false,
                                                            message: "Parece que hay varios niveles de cables. Vamos a tener que cortar el cable correcto de cada nivel para apagar la fuente de alimentación y, por lo tanto, el dispositivo completo. Dime qué cable cortar primero..."
                                                        }
                                                    ]);
                                                    setStep(9);
                                                    setInputEnabled(true);
                                                }, delay);
                                            }, delay);
                                        }, delay);
                                    }, delay)
                                }, delay)
                            }, delay)
                        }, delay)
                    }
                } else {
                    if (commands.length !== 2) {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "La máquina solo acepta comandos en grupos de dos. <br/><br/>Asegúrese de enviarlos con un comando por línea..<br/><br/> Intentemoslo de nuevo."
                            }
                        ]);
                    } else {
                        setMessages([ ...messages,
                            {
                                you: true,
                                message: input.split('\n').join('<br/>')
                            },
                            {
                                you: false,
                                message: "He probado con esos comandos, pero parece que al menos uno de ellos es incorrecto.<br/><br/>Asegúrate de darme comandos que la máquina entienda. <br/><br/><br/>Intentemoslo de nuevo."
                            }
                        ]);
                    }
                }
            }

            if (step === 9) {
                if (lowerInput === 'rojo') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput}. ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("El primer nivel de cables: \n Rojo, verde, azul, negro, rosa\n\n--- NIVEL SUPERADO ---\n\n--- PASANDO AL SIGUIENTE NIVEL ---");
                        setTimeout(() => {
                            setSystemInformation("Segundo nivel de cables: \n Rosa, amarillo, azul");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput}. ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: "Parece que eso era correcto, pasamos al siguiente nivel. <br/><br/> ¿Qué cable debo cortar ahora?"
                                }
                            ]);
                            setStep(10);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['verde', 'negro', 'azul', 'rosa'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput}. ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("El primer nivel de cables: \n Rojo, verde, azul, negro, rosa.\n\n--- MANIPULACIÓN DETECTADA ---\n\n--- EL TEMPORIZADOR SE HA AJUSTADO POR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("El primer nivel de cables: \n Rojo, verde, azul, negro, rosa.");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: "Oh no, parece que ese no era el cable correcto… y el temporizador se ha modificado. Intenta no equivocarte en el próximo. <br/><br/>¿Qué cables quieres que corte?"
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Esa no es una opción, ¡concéntrate!`
                    }
                  ]);
                }
            }

            if (step === 10) {
                if (lowerInput === 'azul') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Segundo nivel de cables: \n Rosa, amarillo, azul\n\n--- NIVEL SUPERADO ---\n\n--- PASANDO AL SIGUIENTE NIVEL ---");
                        setTimeout(() => {
                            setSystemInformation("Tercer nivel de cables: \n Rosa, negro, azul, amarillo");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: "Parece que eso era correcto, pasamos al siguiente nivel. <br/><br/> ¿Qué cable debo cortar ahora?"
                                }
                            ]);
                            setStep(11);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['amarillo', 'rosa'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Segundo nivel de cables: \n Rosa, amarillo, azul \n\n--- MANIPULACIÓN DETECTADA ---\n\n--- EL TEMPORIZADOR SE HA AJUSTADO POR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("Segundo nivel de cables: \n Rosa, amarillo, azul");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Esa no es una opción, ¡concéntrate!`
                    }
                  ]);
                }
            }

            if (step === 11) {
                if (lowerInput === 'rosa') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Tercer nivel de cables: \n Rosa, negro, azul, amarillo\n\n--- NIVEL SUPERADO ---\n\n--- PASANDO AL SIGUIENTE NIVEL ---");
                        setTimeout(() => {
                            setSystemInformation("Cuarto nivel de cables: \n Verde, rosa, azul, rojo, amarillo");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: "Parece que eso era correcto, pasamos al siguiente nivel. <br/><br/> ¿Qué cable debo cortar ahora?"
                                }
                            ]);
                            setStep(12);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else if (['amarillo', 'negro', 'azul'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Tercer nivel de cables: \n Rosa, negro, azul, amarillo\n\n--- MANIPULACIÓN DETECTADA ---\n\n--- EL TEMPORIZADOR SE HA AJUSTADO POR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("Tercer nivel de cables: \n Rosa, negro, azul, amarillo");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: wireCutFailMessage
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Esa no es una opción, ¡concéntrate!`
                    }
                  ]);
                }
            }

            if (step === 12) {
                if (lowerInput === 'azul') {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                        }
                    ]);

                    setStopTimer(true);
                    setTimeout(() => {
                        setSystemInformation("Cuarto nivel de cables: \n Verde, rosa, azul, rojo, amarillo\n\n--- NIVEL SUPERADO ---\n\n--- TODOS LOS NIVELES COMPLETOS ---");
                        setTimeout(() => {
                            setSystemInformation("--- Fuente de alimentación apagada ---");
                            setImage(AllDisabled);
                            setTimeout(() => {
                                setSystemInformation(`--- TODOS LOS SISTEMAS ESTÁN APAGADOS ---\n\n--- TEMPORIZADOR FINALIZADO EN ${timerFrozen ? '60:00+' : calculateTimerString(timer, 2)} ---`);
                                setImage(Defused);
                                setTimeout(() => {
                                    if (!timerFrozen) {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                            },
                                            {
                                                you: false,
                                                message: `¡Increíble, hemos conseguido desactivar todos los componentes! Has hecho un fantástico trabajo en desarmar el dispositivo. Ahora toda Molvaria está a salvo de los horrores que Yakov pretendía disipar. Bien, creo que tenemos tiempo suficiente para encontrarle. He estado pensando dónde podría estar mientras nos ocupábamos del dispositivo. <a href='http://www.conspiralist.com/message-from-claire-1-es/?playerTime=${ image === Defused ? '45:00 +' : elapsedTime(timer, 0)}'>Haz click aquí</a> para acceder a la información que encontré.`
                                            }
                                        ]);
                                    } else {
                                        setMessages([ ...messages,
                                            {
                                                you: true,
                                                message: input
                                            },
                                            {
                                                you: false,
                                                message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                            },
                                            {
                                                you: false,
                                                message: "¡Increíble, hemos conseguido desactivar todos los componentes! Has hecho un fantástico trabajo en desarmar el dispositivo. Ahora toda Molvaria está a salvo de los horrores que Yakov pretendía disipar. Desafortunadamente, creo que mientras nosotros desactivábamos el dispositivo, él ha logrado escapar. Pero no pasa nada, nosotros hemos conseguido detener lo que tenía planeado. El próximo paso es compartir las noticias acerca de lo que el gobierno tenía entre manos. Mientras tú desactivabas el dispositivo, yo he estado trabajando en algunas cosas. <a href='http://www.conspiralist.com/message-from-claire-2-es/?playerTime=60:00+'>Click here</a> to see our next steps."
                                            }
                                        ]);
                                    }
                                }, delay);
                            }, delay);
                        }, delay);
                    }, delay);
                } else if (['rojo', 'rosa', 'verde', 'amarillo'].includes(lowerInput)) {
                    setInputEnabled(false);
                    setMessages([ ...messages,
                        {
                            you: true,
                            message: input
                        },
                        {
                            you: false,
                            message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                        }
                    ]);

                    setTimeout(() => {
                        setSystemInformation("Cuarto nivel de cables: \n verde, rosa, azul, rojo, amarillo\n\n--- MANIPULACIÓN DETECTADA ---\n\n--- EL TEMPORIZADOR SE HA AJUSTADO POR ERROR ---");
                        setTimeout(() => {
                            setSystemInformation("Cuarto nivel de cables: \n verde, rosa, azul, rojo, amarillo");
                            setMessages([ ...messages,
                                {
                                    you: true,
                                    message: input
                                },
                                {
                                    you: false,
                                    message: `Vale, cortaré el cable ${lowerInput} ¡Aquí va!`
                                },
                                {
                                    you: false,
                                    message: "Oh no, parece que ese no era el cable correcto… y el temporizador se ha modificado. Intenta no equivocarte en el próximo. <br/><br/>¿Qué cables quieres que corte? "
                                }
                            ]);
                            setTimerInterval(timerInterval * 0.6);
                            setInputEnabled(true);
                        }, delay);
                    }, delay);
                } else {
                  setMessages([ ...messages,
                    {
                        you: true,
                        message: input
                    },
                    {
                        you: false,
                        message: `Esa no es una opción, ¡concéntrate!`
                    }
                  ]);
                }
            }
        }, delay);
    }

    const TimerFunction = React.useCallback(() => {
        if (!stopTimer) {
            if ((timer - (1 + timePenalty)) < 1 && !timerFrozen) {
                console.log(timerFrozen);
                setTimerFrozen(true);
                setError(true);
            } else {
                timerDispatch();
                setTimePenalty(0);
            }
        }
    }, [stopTimer, timer, timerFrozen, timerDispatch, setError, timePenalty]);

    React.useEffect(() => {
        var clock = null;

        const resetTimeout = () => {
            clock = setTimeout(TimerFunction, timerInterval * 1000);
        }

        console.log('resetTimer');
        resetTimeout();

        return () => {
            clearTimeout(clock);
        }
    }, [timerInterval, TimerFunction, initialState]);

    React.useEffect(() => {
        document.querySelector('#chat-box').scrollTop = document.querySelector('#chat-box').scrollHeight;
    }, [ChatLog]);

    return (
        <div className="main-game">
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="left-panel">
                <Fieldset>
                    <div className="timer">
                        <p>Tiempo estimado hasta el lanzamiento:</p>
                        <p style={{ color: timer < 300 ? 'red' : 'black' }}>{ timerFrozen ? 'FROZEN' : `${ timerValue }` }</p>
                    </div>
                </Fieldset>
                <Fieldset label="Información del sistema">
                    <TextField
                        value={ systemInformation }
                        placeholder='Esperando instrucciones...'
                        onChange={(e) => {}}
                        fullWidth
                        multiline
                        style={{ height: '100%', minHeight: 166 }}
                    />
                </Fieldset>

                <Fieldset label="Diagnóstico de dispositivo" style={{ maxHeight: 150 }}>
                    <div className="gif-panel">
                        <div className="content">
                            <img src={image} alt="" style={{ height: '100%', width: '100%' }}/>
                        </div>
                    </div>
                </Fieldset>
            </Panel>
            <Panel variant='outside' shadow style={{ width: '100%', height: '100%', padding: '1rem' }} className="right-panel">
                <Fieldset label="Z8 WTP Chat interno:">
                    <div className="chat-section">
                        <TextField
                            value=""
                            placeholder='*** Solo para uso de empleados  ***'
                            onChange={(e) => {}}
                            fullWidth
                            className="internal-use-box"
                        />
                        <div id="chat-box" className="gif-panel chatlog no-before" style={{ overflowY: 'scroll', height: `${ 330 - (25 * (rows - 1))}px`, scrollPaddingTop: '1rem' }}>
                            { ChatLog }
                        </div>
                        <Fieldset className="input-box">
                            <TextField
                                ref={userInput}
                                // value={inputText}
                                placeholder="Empieza a escribir aquí..."
                                // onChange={debounce((e) => { setInputText(e.target.value); console.log('Changed', e.target.value) }, 1000)}
                                onKeyDown={ handleKeyDown }
                                enabled={ inputEnabled }
                                fullWidth
                                multiline={rows !== 1}
                                rows={rows}
                            />
                            <Button onClick={ sendMessage } disabled={!inputEnabled} style={{ height: '100%' }}>
                                Mandar
                            </Button>
                        </Fieldset>
                    </div>
                </Fieldset>
            </Panel>
        </div>
    )
}
